import React from 'react';
import { Helmet } from 'react-helmet';

import { css } from '@emotion/react';

import { Footer } from '../components/Footer';
import { TransitionNavigation } from '../components/header/SiteNav';
import { PostFullContent } from '../components/PostContent';
import { Wrapper } from '../components/Wrapper';
import IndexLayout from '../layouts';
import { NoImage, PostFull, PostFullHeader, PostFullTitle } from '../templates/post';
import ReactFlow, { FlowElement } from 'react-flow-renderer';
import { FastAppsLogo } from "../components/header/SiteNavLogo";
import { graphql } from "gatsby";
import { Content } from "../components/body/Body";
import Demo from "../demo.png";


const BrandGradient = css`
    background-image: linear-gradient(35deg, #3381d5 0%, #13365C 100%);
`;

const Banner = css`
  background-size: cover;
  background-position: center top;
  color: #fff;
  font-family: 'Roboto', sans-serif;
  text-align: center;
  padding: 150px 0 50px 0;

  position: relative;
  z-index: 1;
`;

const BannerFooter = css`
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
    z-index: 0;
    pointer-events: none;
`;

const SubscribeFooter = css`
  border-radius: 10px;
  padding: 35px 40px;
  color: #fff;
  margin: auto;
  width: 50vw;
  z-index: 1;
  margin-bottom: -100px;

  h3 {
      margin-top: 0;
  }
`;

const MessagePill = css`
    font-family: 'Roboto', sans-serif;
    display: inline-block;
    border-radius: 4em;
    padding: 9px 22px;
    margin-bottom: 22px;
    background-color: #ffffff26;
`;

const outputs = ["HTTP Calls", "WebHooks", "Emails", "Text Messages", "Payments"];

export const pageQuery = graphql`
  query {
    allMarkdownRemark(sort: {fields: [frontmatter___date], order: DESC}, filter: {frontmatter: {layout: {eq: "feature"}}}) {
      edges {
        node {
          timeToRead
          frontmatter {
            title
            date
            color
            tags
            draft
            description
            image {
                publicURL
            }
          }
          excerpt
          fields {
            layout
            slug
          }
        }
      }
    }
  }
`;

const graphStyles = {
    width: 900,
    height: 580,
    margin: "auto"
};

const IndexPage: React.FC = (props: any) => {
    /*
    {props.data.allMarkdownRemark.edges.map((feature) => {
        // filter out drafts in production
        return ((feature.node.frontmatter.draft !== true || process.env.NODE_ENV !== 'production') && (
            <a href={feature.node.fields.slug} css={FeatureCardStyles} className="post-card">
                <article>
                    <img
                        alt={`${feature.node.frontmatter.title} cover image`}
                        src={feature.node.frontmatter.image.publicURL}
                    />
                    <h4 style={{ color: feature.node.frontmatter.color }}>
                        {feature.node.frontmatter.title}
                    </h4>
                    <p>
                        {feature.node.frontmatter.description}
                    </p>
                </article>
            </a>
        ));
    })}
    */

    const listOfIntegrations = props.data.allMarkdownRemark.edges;
    const countOfIntegrations = listOfIntegrations.length;

    const verticalSpace = 60;
    const verticalCenter = (countOfIntegrations * verticalSpace) / 2 - 8;

    function getPosition(index: number): { x: number, y: number } {
        let xPosition: number = 0;

        if (index % 3 === 1) {
            xPosition = 100;
        }

        if (index % 3 === 2) {
            xPosition = 200;
        }

        return {
            x: xPosition,
            y: 150 * Math.floor(index / 3)
        };
    }

    const elements: FlowElement[] = [
        ...listOfIntegrations.map((integration: any, index: number) => ({
            id: integration.node.frontmatter.title,
            type: "input",
            data: {
                label: <>
                    <img
                        src={integration.node.frontmatter.image.publicURL}
                        alt={integration.node.frontmatter.title}
                        style={{
                            height: 50,
                            margin: "auto",
                            display: "block"
                        }}
                    />
                    <span
                        style={{
                            color: integration.node.frontmatter.color,
                            fontWeight: "bold",
                            fontFamily: "Roboto",
                            fontSize: "14px"
                        }}
                    >
                        {integration.node.frontmatter.title}
                    </span>
                </>
            },
            position: getPosition(index),
            sourcePosition: "right",
            style: {
                borderRadius: "50%",
                width: 100,
                height: 100,
                border: "none"
            },
            draggable: false,
            selectable: false,
        })),
        ...listOfIntegrations.map((integration: any) => ({
            id: `${integration.node.frontmatter.title}-app`,
            source: integration.node.frontmatter.title,
            target: "app",
            animated: true
        })),

        {
            id: 'app',
            data: { label: <FastAppsLogo /> },
            position: {
                x: 400,
                y: verticalCenter
            },
            targetPosition: "left",
            sourcePosition: "right",
            draggable: false,
            selectable: false,
        },

        ...outputs.map((outputName, index) => ({
            id: outputName,
            type: "output",
            data: { label: outputName },
            position: {
                x: index % 2 ? 700 : 750,
                y: verticalSpace * index + verticalSpace
            },
            targetPosition: "left",
            draggable: false,
            selectable: false,
        })),
        ...outputs.map((outputName) => ({
            id: `${outputName}-app`,
            source: "app",
            target: outputName,
            animated: true
        })),
    ];

    return (
        <IndexLayout>
            <Helmet>
                <title>Take Back Development - FastApps</title>
            </Helmet>
            <Wrapper>
                <TransitionNavigation isSticky={true} mode="white" />
                <div css={BrandGradient} style={{ position: "relative" }}>
                    <div css={BannerFooter}>
                        <svg width="1920" height="500" viewBox="0 0 1920 500" style={{ width: "100%", height: "auto" }}>
                            <defs><clipPath><rect width="1920" height="500"></rect></clipPath></defs>
                            <g data-name="Shape"><rect data-name="Rectangle 134" width="2356" height="781" transform="translate(-136.868 401.948) rotate(-11)" fill="#F5F5F5"></rect></g>
                        </svg>
                    </div>
                    <div css={[Banner]}>
                        <a css={MessagePill} style={{ color: "#fff" }} href="http://app.fastapps.io/user/signup">
                            <strong>Get started</strong> for free
                        </a>
                        <h1>Developers its time<br />to take back development</h1>
                        <p>
                            It's time to stop creating another <abbr title="Creating, Reading, Updating, and Deleting">C.R.U.D.</abbr> app, another live report, another salesforce integration.<br />
                            It's time to do the valuable work you want to do.<br />
                        </p>
                        <div style={{ margin: "auto" }}>
                            <img
                                src={Demo}
                                style={{
                                    border: "4px solid #13365c",
                                    background: "#EFEFEF",
                                    borderRadius: 10,
                                    width: "75vw",
                                    aspectRatio: "1.4 / 1"
                                }}
                            />
                        </div>
                    </div>
                </div>
                <Content>
                    <article className="post page" css={[PostFull, NoImage]}>
                        <div>
                            <ReactFlow
                                defaultZoom={1.0}
                                elements={elements}
                                style={graphStyles}
                                zoomOnPinch={false}
                                zoomOnScroll={false}
                                zoomOnDoubleClick={false}
                                minZoom={1.1}
                                maxZoom={1.1}

                                paneMoveable={false}
                            />
                        </div>


                        <PostFullHeader className="post-full-header">
                            <PostFullTitle className="post-full-title">Take Back Development</PostFullTitle>
                        </PostFullHeader>

                        <PostFullContent className="post-full-content">
                            <div className="post-content">
                                <p>
                                    Stop being a cost center and start being a driving force for revenue.
                                </p>
                            </div>
                        </PostFullContent>
                    </article>
                </Content>
                <div css={[SubscribeFooter, BrandGradient]}>
                    <h3>Subscribe our newsletter</h3>
                    <p>Can't decide yet</p>

                    <input type="email" />
                    <button type="submit">Get Access</button>
                </div>
                <Footer />
            </Wrapper>
        </IndexLayout>
    );
};

export default IndexPage;
